(function($){
  $(document).ready(function(){
    //determine the current page,
    let page = window.location.href, opt='';

    console.log(page);

    $('select[name="breedlist"]').find('option[value="'+opt+'"]').prop('selected', 'selected');
  })
})(jQuery)
