let sliders = {
    init: function() {
        $('#hero_banner').not('slick-initialized').slick({
            fade: true,
            infinite: true,
            dots: false,
            appendDots: $('.banner-dots'),
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            appendArrows: $('.banner-controls'),
            prevArrow: '<div class="arrow-left"><i class="far fa-chevron-left fa-3x"></i></div>',
            nextArrow: '<div class="arrow-right text-right"><i class="far fa-chevron-right fa-3x"></i></div>',
            adaptiveHeight: true,
        });

        $('.testimonials').not('slick-initialized').not($('body')).slick({
            fade: true,
            infinite: true,
            dots: false,
            appendDots: $('.testimonial-dots'),
            arrows: true,
            appendArrows: $('.testimonial-controls'),
            prevArrow: '<div class="arrow-left"><i class="far fa-chevron-left fa-3x"></i></div>',
            nextArrow: '<div class="arrow-right text-right"><i class="far fa-chevron-right fa-3x"></i></div>',
            adaptiveHeight: true,
        });
    },
    breakdown: () => {
        $('#hero_banner.slick-initialized').slick("unslick");
        $('.testimonials.slick-initialized').slick("unslick");
    },
};

$(document).ready(function() {
    let Sliders = Object.create(sliders);
    Sliders.init();
});