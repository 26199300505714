// Load popperjs
window.Popper = require('popper.js');
// Load jquery
window.$ = require('jquery');
// Load bootstrap
require('bootstrap');
// reference window.jQuery as window.$
window.jQuery = window.$;
// Load slick carousel
require('slick-carousel');
// Load a custom javascript file
require('./sliders');
// require('./admin.js');
require('./fontawesome');
require('./load-more');
require('./woocommerce');
require('./article-selector');
require('./breed-article');
