export default {
  "prefix": "burger-icon",  // probably better to use a custom one
  "iconName": "shopping-bag",
  "icon": [
    24,          // viewBox width
    24,          // viewBox height
    [],           // ligatures
    "f9e8",       // unicode codepoint - private use area
    "M6 23.73L3 21.608V7.408L6 8.767V23.73ZM8 8.875V24L21 22.046V7L8 8.875ZM13.963 1C11.866 1 10.005 3.005 10.001 5.266L10 6.949C10 7.254 10.273 7.489 10.575 7.443C10.819 7.406 11 7.196 11 6.949V5.268C11.003 3.558 12.416 2 13.963 2C14.5 2 14.979 2.195 15.347 2.564C15.769 2.987 16.001 3.599 16 4.291V6.038C16 6.343 16.273 6.578 16.575 6.532C16.818 6.495 16.998 6.286 16.998 6.04L17 4.291C17.002 2.387 15.68 1 13.963 1ZM7.573 6.995C7.818 6.958 8 6.748 8 6.5V4.268C8.002 2.558 9.416 1 10.963 1L11.125 1.015C11.491 0.732 11.89 0.502 12.313 0.332C11.908 0.125 11.455 0 10.963 0C8.867 0 7.005 2.005 7.001 4.266V6.501C7.001 6.807 7.273 7.039 7.573 6.995Z",  // path
  ],
}
