jQuery(document).ready(function ($) {
  // JavaScript to be fired on all pages, after page specific JS is fired
  let pp = 2; // next page to call
  // JavaScript to be fired on all pages, after page specific JS is fired
  $('.products-load #load-more').on('click', function (e) {
    e.preventDefault();
    $(this).hide();
    $.ajax({
      // eslint-disable-next-line no-undef
      url: ajaxloadmore.ajaxurl,
      type: 'post',
      data: {
        action      : 'ajax_loadmore',
        // eslint-disable-next-line no-undef
        query_vars  : ajaxloadmore.query_vars,
        page        : pp,
      },
      success: function(data) {
        pp++;
        if (data == '') {
          $('.products-load').remove();
        } else {
          $('.products').append(data);
          $('.products-load #load-more').show();
        }
      },
    });
  });
});
