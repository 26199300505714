jQuery(document).ready(function ($) {
  $('body').on( 'change', 'input.variation_id', function() {
    if('' !== $('input.variation_id').val()){
      $('p.price').html($('div.woocommerce-variation-price > span.price').html()).append('<p class="availability">'+$('div.woocommerce-variation-availability').html()+'</p>');
      console.log($('input.variation_id').val());
    } else {
      $('p.price').html($('div.hidden-variable-price').html());
      if($('p.availability'))
        $('p.availability').remove();
      console.log('NULL');
    }
  });

  $('body').on( 'click', 'button.plus, button.minus', function() {
    // Get current quantity values
    var qty = $( this ).closest( 'form.cart' ).find( '.qty' );
    var val   = parseFloat(qty.val());
    var max = parseFloat(qty.attr( 'max' ));
    var min = parseFloat(qty.attr( 'min' ));
    var step = parseFloat(qty.attr( 'step' ));

    // Change the value if plus or minus
    if ( $( this ).is( '.plus' ) ) {
      if ( max && ( max <= val ) ) {
        qty.val( max );
      } else {
        qty.val( val + step );
      }
    } else {
      if ( min && ( min >= val ) ) {
        qty.val( min );
      } else if ( val > 1 ) {
        qty.val( val - step );
      }
    }

  });
});
